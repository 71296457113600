import axios from "@/utils/axios";
import axiosWin from "@/utils/axiosWin";
import { getMacInfo , userDevice , bindUserid , popupDevice , account_tel , identity , user_change , codeX , swipe_code } from "@/api";
import { extractInfoFromIDCard } from "@/utils";
import { Notify } from 'vant';

let device = {
    namespaced: true,
    state: {
        code:'',
        msg:'',
        qrcode:'',
        info:null,
        axiosI:0
    },
    mutations: {
        //发送请求
        mainAxios(state,params){
            axios(`/api${getMacInfo}`,params.data)
			.then((data)=>{
                if(data.code==1){
                    state.code = data.data;
                    let equipment = {
                        equipment_code:data.data.info.equipment_code,
                        sys_company_id:data.data.info.sys_company_id,
                        code:data.data.info.qrcode,
                        logo:data.data.info.logo,
                        name:data.data.company.mark?data.data.company.mark:'',
                        mac:params.data.mac,
                        appid:data.data.app_id?data.data.app_id:'',
                        send_template_select:data.data.info.send_template_select
                    }
                    sessionStorage.setItem("equipment",JSON.stringify(equipment));
                    sessionStorage.setItem("flow", JSON.stringify({
                        ...data.data.flow,
                        testing_type:data.data.info.testing_type,
                        device:true
                    }));
                }else{
                    Notify(data.msg);
                    state.msg = data.msg;
                    params._this.$router.push({
                        name:'code',query: {
                            mac:params.data.mac,
                            ban:data.code==402?false:true
                        }
                    });
                }
            });
        },

        //身份证
        identityX( state , params ){
            function identityFun(){
                state.axiosI = state.axiosI+1;
                axiosWin(`identity_${state.axiosI}`,`${identity}`,params.data , function(data){
                    if(!data.code){
                        if(!data.cardType){
                            let birthday = `${data.data.birthday.substring(0,4)}-${data.data.birthday.substring(4,6)}-${data.data.birthday.substring(6,9)} 00:00`;
                            data.data.birthday = birthday;
                            data.data.gender = data.data.gender==1?'男':'女';
                            state.info = data.data;
                            sessionStorage.setItem("info",JSON.stringify(data.data));
                            params.fun(data.data);
                        }else{
                            Notify({ type: 'danger', message: '暂不支持外国身份证！' });
                        }
                    }else{
                        Notify({ type: 'danger', message: data.msg });
                    }
                })
            }
            identityFun();
        },

        //扫码
        codeX( state , params ){
            state.axiosI = state.axiosI+1;
            function codeFun(){
                axiosWin(`codeX_${state.axiosI}`,`${codeX}`,params.data , function(data){
                    if(!data.code){
                        let birthday = extractInfoFromIDCard(data.data.idCode).birthday;
                        let gender = extractInfoFromIDCard(data.data.idCode).gender;
                        data.data.birthday = birthday;
                        data.data.gender = gender;
                        state.info = data.data;
                        sessionStorage.setItem("info",JSON.stringify(data.data));
                        params.fun(data.data);
                    }else{
                        Notify({ type: 'danger', message: data.msg });
                    }
                })
            }
            codeFun();
        },

        //刷医保卡
        swipe( state , params ){
            state.axiosI = state.axiosI+1;
            axiosWin(`swipeCode_${state.axiosI}`,`${swipe_code}`,params.data , function(data){
                if(data.code===0){
                    let birthday = extractInfoFromIDCard(data.data.idCode).birthday;
                    let gender = extractInfoFromIDCard(data.data.idCode).gender;
                    data.data.birthday = birthday;
                    data.data.gender = gender;
                    state.info = data.data;
                    sessionStorage.setItem("info",JSON.stringify(data.data));
                    params.fun(data.data)
                }else{
                    Notify({ type: 'danger', message: data.msg });
                }
            });
        },

        //改变数值
        changeDFun(state,data){
            state[data.key] = data.value;
        },

        //修改用户信息
        userRevise(state,params){
            axios(`/userapi${user_change}`,params.data,'PUT').then((data)=>{
                params.func();
            });
        },
        
        //手机号或姓名登录
        telAxios(state,params){
            let _this = this;
            axios(`/userapi${account_tel}`,params.data,'POST').then((data)=>{
                if(data.code==1){
                    sessionStorage.setItem("token",data.data.token);
                    if(params.token){
                        _this.dispatch('device/axiosBind',{
                            data:{
                                token: params.token    //这里报告的token
                            },
                            url: params.url,
                            query:params.query,
                            _this: params._this
                        });
                    }else{
                        if(params.func){
                            params.func(data.data);
                        }else{
                            params._this.$router.push({
                                name:params.url,
                                query:params.query
                            })
                        }
                    }
                }
            })
        },

        //轮询用户扫码获取token
        lunAxios(state,params){
            let _this = this;
            axios(`/userapi${userDevice}`,params.data)
            .then((data)=>{
                if(data.code == 1 && data.data.token ){
                    sessionStorage.setItem("token",data.data.token);
                    clearInterval(window.Lun);
                    if(params.token){
                        _this.dispatch('device/axiosBind',{
                            data:{
                                token: params.token    //这里报告的token
                            },
                            url: params.url,
                            callback:params.callback,
                            _this: params._this
                        });
                    }else{
                        params._this.$router.push({
                            name:params.url,
                            query:params.query
                        })
                        
                    }
                }
            });
        },

        //添加设备登录的uuid
        popupAxios(state,params){
            axios(`/userapi${popupDevice}`,params.data,'POST')
            .then((data)=>{
                if(data.code == 1){
                    params.openFun(params.data)
                }
            });
        },

        //用将用户和设备报告绑定
        bindAxios(state,params){
            axios(`/api${bindUserid}`,params.data,'POST')
            .then((data)=>{
                if(data.code == 1){
                    if(params.callback){
                        params.callback(params);
                    }else if(params.url=='report'){
                        Notify({ type: 'success', message: '登录成功！' });
                        setTimeout(function(){
                            window.location.reload();
                        },1000)
                    } else if(params.url){
                        params._this.$router.push({
                            name:params.url,
                            query:params.query
                        })
                    }
                }else{
                    Notify(data.msg);
                }
            });
        }
    },

    actions: {              //异步方法
        axiosData({commit},params){
            commit('mainAxios',params)
        },
        axiosLun({commit},params){
            commit('lunAxios',params)
        },
        popupMain({commit},params){
            commit('popupAxios',params)
        },
        axiosBind({commit},params){
            commit('bindAxios',params)
        },
        axiosTel({commit},params){
            commit('telAxios',params)
        },
        identityData({commit},params){
            commit('identityX',params)
        },
        codeData({commit},params){
            commit('codeX',params)
        },
        userReviseData({commit},params){
            commit('userRevise',params)
        },
        swipeData({commit},params){
            commit('swipe',params)
        },
    },
    getters: {},
    
}

export default device;

