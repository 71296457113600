import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes:[
        //设备首页
        { path:'/' , name:'device' , component: resolve => require(['./views/device'],resolve) },
        //激活码
        { path:'/code' , name:'code' , component: resolve => require(['./views/code'],resolve) },
        //评估系统的介绍
        { path:'/confirmAssess' , name:'confirmAssess' , component: resolve => require(['./views/confirmAssess'],resolve) },
        { path:'/confirmAssess/:id' , name:'confirmAssess_id' , component: resolve => require(['./views/confirmAssess'],resolve) },
        //面诊介绍
        { path:'/confirmToface' , name:'confirmToface' , component: resolve => require(['./views/confirmToface'],resolve) },
        //舌诊介绍
        { path:'/confirmTongue' , name:'confirmTongue' , component: resolve => require(['./views/confirmTongue'],resolve) },
        //问诊：伸舌头介绍
        { path:'/confirmask' , name:'confirmAsk' , component: resolve => require(['./views/confirmAsk'],resolve) },
        //问诊：拍舌底介绍
        { path:'/confirmPsd' , name:'confirmPsd' , component: resolve => require(['./views/confirmPsd'],resolve) },
        //面诊
        { path:'/step1' , name:'step1' , component: resolve => require(['./views/step1'],resolve) },
        //舌诊：伸舌头
        { path:'/step2' , name:'step2' , component: resolve => require(['./views/step2'],resolve) },
        //舌诊：拍舌底
        { path:'/step3' , name:'step3' , component: resolve => require(['./views/step3'],resolve) },
        //用户中心
        { path:'/user' , name:'user' , component: resolve => require(['./views/user'],resolve) },
        //上传医师资格证
        { path:'/upload' , name:'upload' , component: resolve => require(['./views/upload'],resolve) },
        //填写名字和性别
        { path:'/becomeDoctor' , name:'setUserInfo' , component: resolve => require(['./views/becomeDoctor'],resolve) },
        //扫码添加用户
        { path:'/addUserCode' , name:'addUserCode' , component: resolve => require(['./views/addUserCode'],resolve) },
        //修改用户信息
        { path:'/profile' , name:'profile' , component: resolve => require(['./views/profile'],resolve) },
        //设备问诊
        { path:'/question' , name:'question' , component: resolve => require(['./views/question'],resolve) },
        //开方问诊
        { path:'/prescribeQuestion' , name:'prescribeQuestion' , component: resolve => require(['./views/prescribeQuestion'],resolve) },
        { path:'/prescribeQuestion/:id' , name:'prescribeQuestion_id' , component: resolve => require(['./views/prescribeQuestion'],resolve) },
        //报告详情
        { path:'/report/:id' , name:'report' , component: resolve => require(['./views/report'],resolve) },
        //养生列表
        { path:'/reportList' , name:'reportList' , component: resolve => require(['./views/reportList'],resolve) },
        { path:'/reportList/:id' , name:'reportList_id' , component: resolve => require(['./views/reportList'],resolve) },
        //医生端
        { path:'/doctorReport/:id' , name:'doctorReport' , component: resolve => require(['./views/doctorReport'],resolve) },
        //报告列表
        { path:'/list/:id' , name:'list' , component: resolve => require(['./views/list'],resolve) },
        //添加用户
        { path:'/addUser/:id' , name:'addUser' , component: resolve => require(['./views/addUser'],resolve) },
        //提前注册
        { path:'/login' , name:'login' , component: resolve => require(['./views/login'],resolve) },
        /**
         * 活动页面
         */
        //红包活动
        { path:'/redPacket/:id' , name:'redPacket' , component: resolve => require(['./views/activity/redPacket'],resolve) },

        /**
         * 红包
         */
        //制作红包
        { path:'/redEnvelope/send' , name:'send' , component: resolve => require(['./views/redEnvelope/send'],resolve) },
        //接受红包
        { path:'/redEnvelope/gift' , name:'gift' , component: resolve => require(['./views/redEnvelope/gift'],resolve) },
        { path:'/redEnvelope/gift/:id' , name:'gift' , component: resolve => require(['./views/redEnvelope/gift'],resolve) },
        //当面扫码 - 用户列表
        { path:'/redEnvelope/list' , name:'list2' , component: resolve => require(['./views/redEnvelope/list'],resolve) },
        //用户 - 个人
        { path:'/redEnvelope/user' , name:'user2' , component: resolve => require(['./views/redEnvelope/user'],resolve) },
        //红包管理
        { path:'/redEnvelope/manage' , name:'manage' , component: resolve => require(['./views/redEnvelope/manage'],resolve) },
        //领取红包
        { path:'/redEnvelope/packet/:id' , name:'packet' , component: resolve => require(['./views/redEnvelope/packet'],resolve) },
        //当面扫码
        { path:'/redEnvelope/qr_code/:id' , name:'qr_code' , component: resolve => require(['./views/redEnvelope/qr_code'],resolve) },
        //用户扫码
        { path:'/redEnvelope/qr_add/:id' , name:'qr_add' , component: resolve => require(['./views/redEnvelope/qr_add'],resolve) },
        //报告扫码
        { path:'/redEnvelope/qr_report/:id' , name:'qr_report' , component: resolve => require(['./views/redEnvelope/qr_report'],resolve) },
        //报告列表（红包）
        { path:'/redEnvelope/reportList' , name:'redReportList' , component: resolve => require(['./views/redEnvelope/reportList'],resolve) },
        { path:'/redEnvelope/reportList/:id' , name:'redReportList_id' , component: resolve => require(['./views/redEnvelope/reportList'],resolve) },

        //404
        { path:'/*' , name:'404',  component: resolve => require(['./views/404'],resolve) }
    ]
})
