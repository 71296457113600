export default function ajaWin( info ,url = '' , params = {} ,  fun , type='GET' , header){
    let headers = {
        'Content-Type':'application/json',
    }
    let token = sessionStorage.token;
    if(token){
        headers['Authorization'] = token;
    }
    if(header){
        headers=header;
    }
    let obj = {
        info,
        url,
        data:params,
        method:type,
        headers,
        error:''
    }
    console.log('发送请求：',obj);
    window.ipcRenderer.send("ajax",obj);
    window.ipcRenderer.on(info, (e, msg) => {
        console.log('接收请求:',msg);
        fun(msg);
    });
}